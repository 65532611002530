.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card { /* overwrite some bootstrap css to make it prettier*/
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: black;
  border: 1px solid gray;
  border-radius: 5px;
  color: white;
}

.primaryButton {
  width: 100%;
  background-color: red;
  color: white;
  border-radius: 5px;
  padding: 8px;
}

.primaryButton:hover {
  background-color: whitesmoke;
  color: black;
}

.height-40px {
  height: 40px;
}

.Logo {
  border-radius: 5px;
  max-width: 200px;
  height: auto;
  align-self: center;
}

.detailsLogo {
  border-radius: 5px;
  max-width: 300px;
  height: auto;
  align-self: center;
}

.w-10 {
  width: 10%;
}

.Toast {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}