@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 50px;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
  background-color: black;
  color: white;
  border-radius: 10px 10px 0 0;
}